// adapted from:
// https://css-tricks.com/snippets/css/shake-css-keyframe-animation/
// https://www.w3schools.com/howto/howto_css_shake_image.asp
.jiggle {
    animation: shake 0.5s;
    transform: translate3d(0, 0, 0);
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(-1deg); }
    100% { transform: translate(0px, 0px) rotate(0deg); }
  }