@import "~bootswatch/dist/cosmo/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/cosmo/bootswatch";

@import "./components/GameBoard/GameBoardStyles.scss";

.progress {
    height: 1rem;
    .progress-bar {
        font-size: 18px;
        line-height: 18px;
    }
}