.gameboard {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    max-width: 350px;
    max-height: 350px;
    height: calc(100vh - 200px);
    width: calc(100vh - 200px);
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(3, 33%);
    grid-template-rows: repeat(3, 33%);

    .tile {
      align-self: auto;
      grid-column-start: auto;
      grid-column-end: auto;
      grid-row-start: auto;
      grid-row-end: auto;
  }

}

@include media-breakpoint-up(sm) { 
  .gameboard {
    max-width: 560px;
    max-height: 560px;
 }
}
@include media-breakpoint-up(md) { 
  .gameboard {
    max-width: 700px;
    max-height: 700px;
 }
 }
@include media-breakpoint-up(lg) { 
  .gameboard {
    max-width: 800px;
    max-height: 800px;
 }
 }